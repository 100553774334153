import gsap, { Power1 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";

export default class Preloader {
    _container;
    _progress;

    static init () {
        this._container = GetBy.id('Preloader');
        this._progress = GetBy.class('__progress', this._container);
        this._container.style.display = "none";
    }

    static update (__progress) {
        
    }

    static hide (__call) {
        this._container.style.display = "none";
        if(__call) __call();
    }
}
