import { GetBy } from "../_app/cuchillo/core/Element";
import { Metrics } from "../_app/cuchillo/core/Metrics";
import { Maths } from "../_app/cuchillo/utils/Maths";
import Grid from "./Grid";


export default class Radar {
  static canvas = document.createElement('canvas');
  static ctx = Radar.canvas.getContext('2d');
  static particles = [];
  static isEnabled = false;
  static tick = 0;
  static total = 0;
  static ratio = .75;
  static options = {
    ratio: .25,
    maxSpeed: this.getSize(10),
    minSpeed: this.getSize(2),
    maxLife: 10,
    minLife: 4,
    multLife: 20,
    lineWidth: this.getSize(1),
    color: '#FFF02F',
    particlePointSize: this.getSize(2),
    particleSize: this.getSize(16),
  };
  
  static init(__container = document.body) {
    __container.appendChild(this.canvas);
    this.resize();
    if(this.particles.length === 0) this.setupParticles();
    
    this.canvas.id = "CanvasRadar"
    this.canvas.style.position = "fixed";
    this.canvas.style.width = "100%";
    this.canvas.style.height = "100%";
    this.canvas.style.pointerEvents = "none";
  }

  static setupVoices() {}

  static setupParticles(){
    const domLines = [...GetBy.selector("[data-text-small]")];
    domLines.map(item => {
      this.total = this.particles.push(new Radar__Particle(item.textContent));
    });
  }

  static start() {
    this.isEnabled = true;
  }

  static stop() {
    this.isEnabled = false;
    this.ctx.clearRect(0, 0, this.width, this.height);
  }

  static draw() {
    if(!this.isEnabled) return;
    if(this.tick%3==0) {;
      this.ctx.clearRect(0, 0, this.width, this.height);
    }

    
    for(let i = 0; i<this.total; i++) {
      const particle = this.particles[i];
      particle.update();

      

      if(i===0) this.ctx.moveTo(particle.x, particle.y);
      else      {
        const ant = this.particles[i-1];
        

        this.ctx.moveTo(ant.x, ant.y);
        this.ctx.strokeStyle = this.options.color;
       
        this.ctx.lineTo(particle.x, particle.y);
        this.ctx.stroke();
      }

      
    
      
    }

    for(let i = 0; i<this.total; i++) {
      const particle = this.particles[i];
      particle.draw();
    }
    
    for(let i = 0; i<this.total; i++) {
      const particle = this.particles[i];
      particle.drawText();
    }
  }

  static resize() {
    this.width = this.getSize(window.innerWidth);
    this.height = this.getSize(window.innerHeight);
    this.canvas.setAttribute("width", this.width);
    this.canvas.setAttribute("height", this.height);
  }

  static loop() {
    if(this.isEnabled) {
      this.tick++;
      this.draw();
    }
  }

  static getSize(__n) {
    return __n * this.ratio;
  }
}

class Radar__Particle {
  text;
  type;
  x = 0;
  y = 0;
  life = 1000;
  position = {
    x: 0,
    y: 0,
  }
  speed = {
    x: 0,
    y: 0
  }
  offsetX = Radar.getSize(10);
  offsetY = Radar.getSize(6);
  style = {
    size:Radar.getSize(16),
    unit:"px",
    fontFamily:"SpaceMono-Regular",
    color:"#FFF02F",
    textAlign:"left"
  };

  constructor(__text) {
    this.type = Maths.maxminRandom(2,1);
    this.text = __text.toUpperCase();
    this.reset();
  }

  reset() {
    this.x = Maths.maxminRandom(Radar.width * .9,Radar.width * .4);
    this.y = Maths.maxminRandom(Radar.height * .6,Radar.height * .1);
    this.position = {
      x: this.x,
      y: this.y
    }
    this.life = Maths.maxminRandom(Radar.options.maxLife, Radar.options.minLife) * Radar.options.multLife;
    const modX = Math.random() > .5? 1 : -1;
    const modY = Math.random() > .5? 1 : -1;
    this.speed = {
      x: Maths.maxminRandom(Radar.options.maxSpeed,Radar.options.minSpeed)/10 * modX,
      y: Maths.maxminRandom(Radar.options.maxSpeed,Radar.options.minSpeed)/10 * modY
    }
  }

  update() {
    this.position = {
      x: this.position.x + this.speed.x,
      y: this.position.y + this.speed.y
    }
    this.x = Math.round(this.position.x/Metrics.GRID_SIZE_S) * Metrics.GRID_SIZE_S;
    this.y = Math.round(this.position.y/Metrics.GRID_SIZE_S) * Metrics.GRID_SIZE_S;
    this.life--;

    if(this.life <= 0) {
      this.reset();
    }
  }

  draw() {


      
      /*this.ctx.rect(
        this.position.x - Radar.options.particlePointSize *.5,
        this.position.y - Radar.options.particlePointSize *.5,
        Radar.options.particlePointSize,
        Radar.options.particlePointSize);
      this.ctx.stroke();*/

      if(this.type == 1) {
        Radar.ctx.globalCompositeOperation = 'destination-out'
        Radar.ctx.beginPath();
        Radar.ctx.arc(this.x, this.y, Radar.options.particleSize - 1, 0, Math.PI * 2, false);
        Radar.ctx.fill();

        Radar.ctx.globalCompositeOperation = 'source-over'
        Radar.ctx.beginPath();
        Radar.ctx.arc(this.x, this.y, Radar.options.particleSize, 0, Math.PI * 2, false);
        Radar.ctx.stroke();
      } else if(this.type==2) {
        Radar.ctx.globalCompositeOperation = 'destination-out'
        Radar.ctx.beginPath();
        Radar.ctx.moveTo(this.x - Radar.options.particleSize, this.y);
        Radar.ctx.lineTo(this.x, this.y - Radar.options.particleSize);
        Radar.ctx.lineTo(this.x + Radar.options.particleSize, this.y);
        Radar.ctx.lineTo(this.x, this.y + Radar.options.particleSize);
        Radar.ctx.lineTo(this.x - Radar.options.particleSize, this.y);
        Radar.ctx.fill();

        Radar.ctx.globalCompositeOperation = 'source-over'
        Radar.ctx.beginPath();
        Radar.ctx.moveTo(this.x - Radar.options.particleSize, this.y);
        Radar.ctx.lineTo(this.x, this.y - Radar.options.particleSize);
        Radar.ctx.lineTo(this.x + Radar.options.particleSize, this.y);
        Radar.ctx.lineTo(this.x, this.y + Radar.options.particleSize);
        Radar.ctx.lineTo(this.x - Radar.options.particleSize, this.y);
        Radar.ctx.stroke();
      }

      Radar.ctx.beginPath();
      Radar.ctx.arc(this.x, this.y, Radar.options.particlePointSize, 0, Math.PI * 2, false);
      Radar.ctx.fillStyle = this.style.color;
      Radar.ctx.fill();//
      Radar.ctx.stroke();      
  }

  drawText() {
      Radar.ctx.font = this.style.size + this.style.unit + " " + this.style.fontFamily;
      Radar.ctx.fillStyle = this.style.color;
      Radar.ctx.textAlign = this.style.textAlign;

      const x = this.x + Radar.options.particleSize + this.offsetX;
      const y = this.y - this.offsetY;
      const w = Radar.ctx.measureText(this.text).width;
      const h = this.style.size;

      Radar.ctx.fillStyle = "#6E6956";
      Radar.ctx.beginPath();
      Radar.ctx.moveTo(x, y);
      Radar.ctx.lineTo(x + w, y);
      Radar.ctx.lineTo(x + w, y + h);
      Radar.ctx.lineTo(x, y + h);
      Radar.ctx.lineTo(x, y);
      Radar.ctx.fill();
      Radar.ctx.fillStyle = this.style.color;
      Radar.ctx.fillText(this.text, this.x + Radar.options.particleSize + this.offsetX, this.y + this.offsetY);
  }
} 
