import Radar from '../components/Radar';
import Storyteller from '../components/Storyteller';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';

export default class Home extends Default {
 
//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  constructor() {
    super();

    Storyteller.init();
    Radar.init();
  }

  afterShow() {
    super.afterShow();
    Radar.start();
    Storyteller.start();
  }

  afterHide() {
    Storyteller.stop();
    Radar.stop();
    super.afterHide();
  }
}

ControllerPage._addPage("home", Home)
