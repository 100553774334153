import { GetBy } from '../core/Element';
import Keyboard, { KEYS }  from '../core/Keyboard';
import { Basics } from '../core/Basics';
import { Accessibility } from '../core/Accessibility';
import { Analytics } from '../core/Analytics';
import Cookies from '../utils/Cookies';

export default class _WinCookies {
  static STATE_OPEN = "OPEN";
  static STATE_CLOSE = "CLOSE";
  static container = GetBy.id("Cookies");
  static _state = "CLOSE";

//==================================================================================================================
//          GETTER SETTER
//==================================================================================================================

  static get isOpen() { return this._state === _WinCookies.STATE_OPEN; }

//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  static add(__var, __value) {
    var now = new Date();
    now.setTime(now.getTime() + 999999999999);
    document.cookie = Basics.id + `_${__var}=${encodeURI(__value)}; expires=${now.toUTCString()}; path=/`;
  }

  static get(name) {
    let result = document.cookie.match(`(^|[^;]+)\s*${Basics.id}_${name}\s*=\s*([^;]+)`)
    return result ? decodeURI(result.pop()) : ""
  }

  static init() {
    this.container.setAttribute("aria-expanded", "false");

    if(Basics.hasCookies) {
      if(!!Cookies.get("cookie_policy")) {
        Analytics.init();
        this.dispose();
      } else {
        this.setup();
        this.show();
      }
    } else {
      this.dispose();
    }
  }

  static setup() {
    Accessibility.trap(this.container);
    Keyboard.add(KEYS.ESC, "CookiesESC", () => { _WinCookies.hide(); });

    GetBy.selector('[data-ok]', this.container)[0].addEventListener(Basics.clickEvent, (e) => { this.ok() }, {once : true});
    GetBy.selector('[data-cancel]', this.container)[0].addEventListener(Basics.clickEvent, (e) => { this.cancel() }, {once : true});
  }

  static ok(item) {
    Cookies.add("cookie_policy", "accepted");
    Analytics.init();
    this.hide();
  }

  static cancel(item) {
    this.hide();
  }

  //SHOW
  static show(__d = 0) {
    this._state = Cookies.STATE_OPEN;
    this.container.setAttribute("aria-expanded", "true");
    this.show__effect();
  }

  static show__effect(__d = 0) {
    this.container.style.opacity = 1;
  }

  //HIDE
  static hide(__d = 0) {
    this._state = _WinCookies.STATE_CLOSE;
    this.hide__effect();
  }

  static hide__effect(__d = 0) {
    this.container.style.display = "none";
    this.dispose();
  }

  static dispose() {
    Accessibility.removeTrap();
    this.container.setAttribute("aria-expanded", "false");
    Keyboard.remove(KEYS.ESC, "CookiesESC");
    this.container.parentNode.removeChild(this.container);

    if(document.body.classList.contains("__accessible")) {
      GetBy.tag("a", GetBy.id("Gotomain"))[0].focus();
    }
  }
}
