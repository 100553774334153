import { GetBy } from "../_app/cuchillo/core/Element";
import { Metrics } from "../_app/cuchillo/core/Metrics";
import { Maths } from "../_app/cuchillo/utils/Maths";
import { Shffl } from '../_app/cuchillo/components/Shffl';


export default class Storyteller {
  static tick = 0;
  static voices;
  static dom = GetBy.class("__story")[0];
  static text = "";
  static lines = [];
  static counter = 0;
  static counterWord = 0;
  static timer;
  static delay = 2000;
  static msg;
  static isEnabled = true;
  
  static init() { 
    if(this.lines.length === 0) Storyteller.setupLines();
  }

  static setupLines(){
    const domLines = [...GetBy.selector("[data-text-large]")];
    domLines.map(item => {
      this.lines.push(item.textContent.split("xxxx").join(" ").split("  ").join(" "));
    });
  }

  static start() {
    Storyteller.isEnabled = true;
    this.next();
  }

  static next() { 
    if(!Storyteller.isEnabled) return;   
    
    const shffl = new Shffl(this.dom, {
      origin: Shffl.RANDOM,
      duration: 2,
      textTo: Storyteller.lines[Storyteller.counter]
    });

    shffl.play();

    Storyteller.counter = Storyteller.counter == Storyteller.lines.length-1? 0 : Storyteller.counter + 1;
    this.timer = setTimeout(()=> {
      this.next();
    }, 10000);
  }

  static stop() {
    
    Storyteller.isEnabled = false;
    clearTimeout(this.timer);
  }

  static loop() {}
}

