import { gsap, Power3, Quad } from 'gsap';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Shffl } from '../_app/cuchillo/components/Shffl';
import { Functions } from '../_app/cuchillo/utils/Functions';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

export default class ScrollItem__Default extends VScroll_Item {
  itemsShffl = [];
  strokeTimeline;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onShow = () => {
      this.showDefault();
    }

   // this.setupShffl();
  }

  setupShffl() {
    const _class = "__" + new Date().getTime();

    [...GetBy.selector("[data-shffl]", this.item)].map(item => {
      const shffl = new Shffl(item, {
        origin: Shffl.RANDOM,
        duration: Math.max(1, Math.min(2, item.innerText.length / 25)),
        textFrom: "",
        textTo: item.innerText.split("xxxx").join(">>>>").split("yyyy").join("<<<<").split("XXXX").join(">>>>").split("YYYY").join("<<<<")
      });

      this.itemsShffl.push(shffl);
    });
  }

  showDefault() {
    this.itemsShffl.map(item => { item.play(); })
  }
}

Scroll._registerClass('default', ScrollItem__Default);
