import { Metrics } from "../_app/cuchillo/core/Metrics";
import InterfaceCanvas from "../_app/cuchillo/layout/InterfaceCanvas";


export default class Grid {
  static canvas = InterfaceCanvas.canvas;
  static ctx = InterfaceCanvas.ctx;
  static _guides = []; 
  static colors = ["#00FF00", "#FF0000", "#0000FF", "#1192e8", "#fa4d56"];
  static size;
  static cols = 29;
  static squareSize = 2;
  
  static getColor(index) { 
    return index < this.colors.length? this.colors[index] : this.colors[0]; 
  }

  static init(__container = document.body) {
    /*__container.appendChild(this.canvas);
    this.resize();
    this.canvas.id = "CanvasGrid"
    this.canvas.style.position = "fixed";
    this.canvas.style.width = "100%";
    this.canvas.style.height = "100%";
    this.canvas.style.zIndex = "99999";
    this.canvas.style.pointerEvents = "none";*/
  }

  static loop() {
    //this.ctx.clearRect(0, 0, this.width, this.height);

    let x = 0;
    let y = 0;
    this.ctx.globalAlpha = .4;
    for(let i=0; i<=this.cols; i++) {
      x = i*this.size;

      for(let j=0; j<=this.rows; j++) {
        y = j*this.size;
        this.ctx.beginPath();
        this.ctx.rect(x - 2, y - 2, 4, 4);
        this.ctx.fillStyle = "#6E6956";
        this.ctx.fill();

      }
    }
    this.ctx.globalAlpha = 1;
  }

  static resize() {
    this.size = Metrics.GRID_SIZE * window.devicePixelRatio; 
    this.rows = Math.ceil((Metrics.HEIGHT * window.devicePixelRatio)/this.size);
    this.width = window.innerWidth * window.devicePixelRatio;
    this.height = window.innerHeight * window.devicePixelRatio;
    /*this.canvas.setAttribute("width", this.width);
    this.canvas.setAttribute("height", this.height);*/
  }
}

